<template>
  <div class="login-bj">
    <div class="login-box">
      <div class="avatar-box">
        <!-- 头像区域 -->
        <img src="../assets/avatar.png" alt="" />
      </div>
      <!-- 表单区域 -->
      <q-form ref="myForm" class="q-gutter-md login-form">
        <q-input
          outlined
          v-model="form.userName"
          label="请输入账号*"
          lazy-rules
          :rules="[
            (val) => (val.trim() && val.trim().length > 0) || '账号不能为空',
            (val) => val.trim().length <= 20 || '用户名不能大于20 个字符',
          ]"
        />

        <q-input
          outlined
          type="password"
          v-model="form.password"
          label="请输入密码*"
          lazy-rules
          :rules="[
            (val) => (val.trim() && val.trim().length > 0) || '密码不能为空',
            (val) =>
              (val.trim().length >= 8 && val.trim().length <= 20) ||
              '长度在 8 到 20 个字符',
          ]"
        />

        <div class="loginbtn">
          <q-btn
            :disable="loginconfim"
            label="登录"
            @click="login"
            color="primary"
            style="width: 100%"
          />
          <!-- <q-btn label="重置" @click="onReset" type="reset"  color="primary" flat class="q-ml-sm" /> -->
        </div>
        <div class="loginbtn2">
          <q-btn
            :disable="loginconfim"
            icon="iconfont icon-weixin"
            flat
            label="微信注册登录"
            @click="wxlogin"
            color="green"
          />
          <q-btn
            :disable="loginconfim"
            icon="iconfont icon-sanwei"
            flat
            label="快速查看"
            color="yellow-10"
            @click="accessCodeconfirm = true"
          />
          <!-- <q-btn label="重置" @click="onReset" type="reset"  color="primary" flat class="q-ml-sm" /> -->
        </div>
      </q-form>
    </div>
    <!-- 备案号 -->
    <div class="Recordnumber"> 
      <a href="https://beian.miit.gov.cn/">高容科技 沪ICP备19046778号-1</a>
    </div>
    <!-- 绑定手机号 -->
    <q-dialog v-model="phoneconfirm" persistent @hide="guanbi">
      <q-card style="width: 450px">
        <q-card-section>
          <div class="text-h6">绑定手机号</div>
        </q-card-section>
        <q-card-section class="items-center">
          <q-form ref="myForms" greedy class="q-gutter-md add">
            <q-input
              mask="###########"
              class="add"
              :dense="true"
              outlined
              v-model="phoneNumber"
              label="手机号"
              lazy-rules
              :rules="[required.phoneNumber, required.phonelength]"
            />
            <q-input
              mask="######"
              class="add"
              :dense="true"
              outlined
              v-model="verifyCode"
              label="验证码"
              lazy-rules="ondemand"
              :rules="[required.verifyCode, required.verifyCodelength]"
            >
              <template v-slot:append>
                <q-btn
                  v-if="timeconfirm"
                  :disable="phoneNumber.length == 11 ? false : true"
                  size="xs"
                  flat
                  color="primary"
                  label="获取验证码"
                  dense
                  @click="getsmsCode"
                />
                <span v-else style="font-size: 13px" class="clock"
                  >{{ countdown }}S</span
                >
              </template>
            </q-input>
          </q-form>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="取消" color="primary" @click="closephone" />
          <q-btn label="绑定" color="primary" @click="bindPhone" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 重建结果窗口 -->
    <q-dialog
      v-model="accessCodeconfirm"
      persistent
      @hide="accessCodeconfirmguanbi"
    >
      <q-card style="width: 450px">
        <q-card-section>
          <div class="text-h6">请输入提取码</div>
        </q-card-section>
        <q-card-section class="items-center">
          <q-form ref="codeForm" greedy class="q-gutter-md add">
            <q-input
              class="add"
              :dense="true"
              outlined
              v-model="accessCode"
              label="提取码"
              lazy-rules
              :rules="[required.accessCode]"
            />
          </q-form>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            label="取消"
            color="primary"
            @click="accessCodeconfirm = false"
          />
          <q-btn label="提取" color="primary" @click="tiqu" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex"; //vuex获取数据
import {
  Login,
  wechatLogin,
  getSmsCode,
  bindphone,
  userOrderview,
} from "@/api/Login.js";
import { required } from "@/api/components/required.js"; // 表单验证规则
import { setItem, removeItem } from "../utils/storage.js";
// import jwt from 'jsonwebtoken'
import { notifys } from '@/api/components/Notify.js'

export default {
  created() {
    this.jiance();
    document.onkeydown=e=>{
      let _key = window.event.keyCode;
      if(_key===13){
        this.login()
      }  
    }
  },
  data() {
    return {
      required,
      loginconfim: false, //控制登录
      form: {
        userName: "",
        password: "", //HCIT.ai@123
      },

      phoneNumber: "", // 手机号
      verifyCode: "", // 验证码
      timeClock: null, //空的倒计时器
      timeconfirm: true, // 控制验证码是否显示
      countdown: 60, //验证码倒计时

      accessCodeconfirm: false, //控制查看重建结果弹窗
      accessCode: "", //提取码
    };
  },
  components: {},
  computed: {
    ...mapState([
      "phoneconfirm", //绑定手机状态
      "phonetoken", // 未绑定手机返回的token
    ]),
  },
  watch: {},
  methods: {
    // 登录
    login() {
      this.$refs.myForm.validate().then(async (outcome) => {
        if (outcome) {
          try {
            const { data: res } = await Login(this.form);
            console.log(res);
            // 登录成功时
            if (res.code === 20000) {
              setItem("token", res.data.token);
              notifys('positive',res.message)
              const token = res.data.token;
              this.$router.push("/home");
              // console.log(jwt.decode(token));
            } else if (res.code === 20001) {
              // 业务失败
              notifys('negative',res.message)
            } else if (res.code === 20002) {
              // 无权限无账号
              notifys('negative',res.message)
            } else {
              notifys('negative',res.message)
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // onReset () {
    //   this.form.userName = ''
    //   this.form.password = ''
    //   this.$refs.myForm.resetValidation()
    // }
    jiance() {
      if ("ActiveXObject" in window) {
        this.$q
          .dialog({
            title: "",
            message: "此系统部分功能不兼容IE,请转用其他浏览器谢谢!!!!",
            ok: { label: "确定", flat: true },
            cancel: { label: "取消", flat: true },
          })
          .onOk(() => {});
        this.loginconfim = true;
      }
    },
    async wxlogin() {
      console.log('process',process.env.NODE_ENV);
      const baseUrl = process.env.NODE_ENV != 'production' ? 'https://aiforcare.com/test-wechat' : 'https://aiforcare.com'
      console.log(baseUrl);
      console.log(window.origin);
      const { data: res } = await wechatLogin({
        // callBackUrl: "http://aiforcare.com/#/wechartLogin",
        callBackUrl: `${baseUrl}/#/wechartLogin`,
      });
      console.log(res);
      // window.open(res.data.url);
      if (res.code === 20000) {
        let link = document.createElement("a"); //创建一个a标签
        link.href = res.data.url;
        link.click(); // 点击a链接
      }
    },
    // 触发倒计时
    sendCode() {
      var timer_num = 60;
      this.timeClock = setInterval(() => {
        timer_num--;
        this.countdown = timer_num;

        if (timer_num == 0) {
          clearInterval(this.timeClock);
          this.timeconfirm = true;
          this.countdown = 60;
        }
      }, 1000);
    },
    // 点击获取验证码
    async getsmsCode() {
      this.timeconfirm = false;
      const { data: res } = await getSmsCode({
        phoneNumber: this.phoneNumber,
      });
      console.log(res);
      if (res.code === 20000) {
        notifys('positive',res.message)
      } else {
        notifys('negative',res.message)
      }
      this.sendCode();
    },
    //绑定手机号
    async bindPhone() {
      this.$refs.myForms.validate().then(async (outcome) => {
        if (outcome) {
          // console.log('1');
          const { data: res } = await bindphone({
            phoneNumber: this.phoneNumber,
            smsCode: this.verifyCode,
          });
          console.log(res);
          if (res.code === 20000) {
            this.$store.commit("editphoneconfirm", false);
            notifys('positive',res.message)
            setItem("token", res.data.token);
            this.$router.push("/home");
          } else {
            notifys('negative',res.message)
            this.$refs.myForm.resetValidation();
            this.phoneNumber = "";
            this.verifyCode = "";
          }
        }
      });
    },
    // 关闭绑定手机对话框
    closephone() {
      this.$refs.myForms.resetValidation();
      this.$store.commit("editphoneconfirm", false);
      this.phoneNumber = "";
      this.verifyCode = "";
    },
    // 关闭绑定手机号窗口
    guanbi() {
      removeItem("token");
      this.$store.commit("getphonetoken", "");
    },
    // 关闭提取重建结果窗口
    accessCodeconfirmguanbi() {
      this.accessCode = "";
    },
    // 点击提取
    tiqu() {
      // 7McT0C
      this.$refs.codeForm.validate().then(async (outcome) => {
        if (outcome) {
          window.open("/#/viewer?code=" + this.accessCode);
        }
      });
      console.log("1");
    },
  },
};
</script>

<style lang='less' scoped>
.login-bj {
  position: relative;
  height: 100%;
  background-color: rgba(255, 255, 255);
  background-size: cover;
  .Recordnumber{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    a{ 
      color: #bbb;
      font-size: 12px;
      text-decoration:none;
    }
    a:hover {
      // text-decoration: underline;
      color: #222;
    }
  }
  .login-box {
    width: 28.125rem;
    // height: 18.75rem;
    height: 21.75rem;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.3125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .avatar-box {
      position: absolute;
      width: 6.25rem;
      height: 6.25rem;
      background: white;
      border-radius: 50%;
      border: 1px solid white;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 10px white;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .login-form {
      position: absolute;
      bottom: 0;
      box-sizing: border-box;
      width: 100%;
      padding: 0 10px;
      .loginbtn {
        display: flex;
        justify-content: center;
        margin-bottom: 0.9375rem;
      }
      .loginbtn2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.9375rem;
      }
    }
  }
}
</style>
